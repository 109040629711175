import React from 'react';
import Usuarios from '../Usuarios/Usuario';
import ModalLogAtendimento from '../../components/Modal/ModalLogAtendimento';

function Teste() {


    return (<ModalLogAtendimento />);
}

export default Teste;